import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Box, Button, Container, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../components/logo';
import { HEADER } from '../../config-global';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import { PATH_AUTH } from '../../routes/paths';
import { bgBlur } from '../../utils/cssStyles';
import navConfig from './nav/config-navigation';
import NavDesktop from './nav/desktop';
import NavMobile from './nav/mobile';
// ----------------------------------------------------------------------
export default function Header() {
    const carouselRef = useRef(null);
    const theme = useTheme();
    const isDesktop = useResponsive('up', 'md');
    const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
    return (_jsxs(AppBar, { ref: carouselRef, color: "transparent", sx: { boxShadow: 0 }, children: [_jsx(Toolbar, { disableGutters: true, sx: {
                    pl: 5, pr: 5,
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_MAIN_DESKTOP,
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(isOffset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        height: {
                            md: HEADER.H_MAIN_DESKTOP - 16,
                        },
                    }),
                }, children: _jsxs(Container, { sx: { height: 1, display: 'flex', alignItems: 'center' }, maxWidth: false, children: [_jsx(Logo, {}), _jsx(Box, { sx: { flexGrow: 1 } }), isDesktop && _jsx(NavDesktop, { isOffset: isOffset, data: navConfig }), _jsx(Button, { component: RouterLink, variant: "contained", to: PATH_AUTH.register, children: "Register" }), !isDesktop && _jsx(NavMobile, { isOffset: isOffset, data: navConfig })] }) }), isOffset && _jsx(Shadow, {})] }));
}
// ----------------------------------------------------------------------
function Shadow({ sx, ...other }) {
    return (_jsx(Box, { sx: {
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            m: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 48px)`,
            boxShadow: (theme) => theme.customShadows.z8,
            ...sx,
        }, ...other }));
}
